import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import userSession from "@App/auth/userSession";
import { config } from "@App/config/config";
import { sendMobileViewModePostMessage, sentryLog } from "@Utils/utils";
import { Auth } from "aws-amplify";
import { getTenantId } from "@App/auth/tenant";

// Set header on retry to avoid retry loop
const NO_RETRY_HEADER = "x-no-retry";

interface BaseServiceRequest {
  path: string;
  type: "get" | "post" | "put" | "delete";
  postData?: any;
  useAuthHeader?: boolean;
  params?: any;
}

const axiosInstance = axios.create({
  // This is to avoid the indexes params to be serialized as an array. Can extract to a property if needed.
  paramsSerializer: {
    indexes: null,
  },
});

// Only for refiring react native embedded requests
let failedRequest: AxiosRequestConfig | null = null;

const refreshTokenLogic = async (resError: AxiosError) => {
  if (userSession.isMobileViewMode) {
    sendMobileViewModePostMessage({ type: "refreshToken" });
    return;
  }

  const session = await Auth.currentSession();
  const accessToken = session.getAccessToken().getJwtToken();
  userSession.accessToken = accessToken;

  if (resError.response?.config.headers) {
    // Type issue present here not in the hub. Could be a version issue.
    (resError.response.config.headers as AxiosHeaders)
      .set("Authorization", `Bearer ${accessToken}`)
      .set(NO_RETRY_HEADER, "true");
    return axiosInstance(resError.response.config);
  } else {
    return Promise.reject("Response missing headers.");
  }
};

export const handleEmbeddedTokenUpdate = (accessToken: string) => {
  userSession.accessToken = accessToken;

  if (failedRequest) {
    if (failedRequest.headers) {
      //@ts-ignore
      failedRequest.headers[NO_RETRY_HEADER] = "true";
      //@ts-ignore
      failedRequest.headers.Authorization = `Bearer ${accessToken}`;
    }
    return axiosInstance(failedRequest);
  }
};

axiosInstance.interceptors.response.use(
  (res) => res,
  async (error) => {
    // Should only have to check 401, but BE returns 403 in some situations
    if (
      error.response &&
      [401, 403].includes(error.response.status) &&
      error.response?.config.headers &&
      !error.response.config.headers[NO_RETRY_HEADER]
    ) {
      try {
        failedRequest = error.response.config;
        return await refreshTokenLogic(error);
      } catch (err) {
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  },
);

export const baseServiceRequest = async <TResponseType = any>(
  request: BaseServiceRequest,
): Promise<AxiosResponse<TResponseType, AxiosError>> => {
  const localTenantId = getTenantId();
  const headers = {
    ...(request.useAuthHeader &&
      userSession.accessToken && {
        Authorization: `Bearer ${userSession.accessToken}`,
      }),
    "x-tenant-id": localTenantId,
  };

  const requestConfig = {
    method: request.type,
    baseURL: config.baseApiUrl,
    url: request.path,
    data: request.postData,
    headers,
    params: request.params,
  };

  let response: AxiosResponse = await axiosInstance(requestConfig);

  if (
    config.sentryEnabled &&
    (response.status === 500 ||
      response.status === 400 ||
      response.status === 404)
  ) {
    const logData = {
      tags: {
        category: "Api Error",
        "cognito.username": userSession.cognitoUsername ?? "Not Logged User",
        version: process.env.REACT_APP_VERSION,
      },
      message: `Http ${response.status.toString()} on ${request?.path}`,
      detail: {
        method: request.type,
        baseURL: config.baseApiUrl,
        url: request.path,
        data: request.postData,
        headers,
        params: request.params,
      },
    };
    sentryLog(logData);
  }

  return response;
};
