import { tenantConfigGet } from "@App/api/general";
import { getTenantTheme } from "@App/api/mock/getTenantTheme";
import { getTenantCmsData } from "@App/api/mock/tenantCmsData";
import { getTenantFooter, getTenantNavbarItems } from "@App/api/tenant";
import userSession from "@App/auth/userSession";
import { config, ConfigSettings, initConfig } from "@App/config/config";
import {
  QUERY_KEY_CMS_DETAIL_PAGES,
  QUERY_KEY_CMS_MAIN_PAGES,
  QUERY_KEY_NAV_BAR_ITEMS,
  QUERY_KEY_TENANT_CONFIG,
  QUERY_KEY_TENANT_FOOTER,
} from "@App/constants/queryKeyConstants";
import store from "@Store/store";
import { setSentryContext, setTenantTheme } from "@Utils/utils";
import { BrowserTracing, init } from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Amplify } from "aws-amplify";
import LogRocket from "logrocket";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

/* i18n */
import { Tenant } from "@App/models/tenant";
import i18n from "./ni18n.config";
import { findAndStoreTenant, getTenantId } from "@App/auth/tenant";

initConfig().then(() => {
  _configureLogRocket(config);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: { refetchOnWindowFocus: false, staleTime: 1000 * 20 },
    },
  });

  // load this right away
  findAndStoreTenant();

  queryClient.fetchQuery([QUERY_KEY_TENANT_CONFIG], () => {
    _setTenantTranslation();
    return tenantConfigGet().then(({ data }) => {
      appendThirdPartyTags(data);
      let showUserway = !!data?.features?.userwayDataAccount;
      let showReactQueryDevtools = true; //Can be set with the tenant response.

      // Set the Title and Favicon from the Tenant Config.
      document.title = data.name;

      const favicon = document.querySelector("link[rel*='icon']");
      if (favicon) {
        favicon.setAttribute(
          "href",
          data.cmsSettings.websiteSettings.communityIcon,
        );
      }
      const appleTouchIcon = document.querySelector(
        "link[rel*='apple-touch-icon']",
      );
      if (appleTouchIcon) {
        appleTouchIcon.setAttribute(
          "href",
          data.cmsSettings.websiteSettings.communityIcon,
        );
      }

      // Configure Amplify.
      Amplify.configure({
        Auth: {
          region: data.awsSettings.region,
          userPoolId: data.awsSettings.userPoolId,
          userPoolWebClientId: data.awsSettings.userPoolPublicClientId,
          mandatorySignIn: true,
          oauth: {
            domain: config.domain,
            scope: [
              "phone",
              "email",
              "profile",
              "openid",
              "aws.cognito.signin.user.admin",
            ],
            redirectSignIn: config.redirectSignIn,
            redirectSignOut: config.redirectSignOut,
            responseType: "code",
          },
        },
      });

      // Tenant dependent data fetching
      const tenantId = getTenantId();
      if (!tenantId) {
        throw new Error("Tenant not found");
      }

      getTenantTheme().then((themeData) =>
        setTenantTheme(themeData.data, data),
      );

      queryClient.prefetchQuery({
        queryKey: [QUERY_KEY_CMS_MAIN_PAGES],
        queryFn: () => getTenantCmsData(tenantId, "main-pages"),
      });

      queryClient.prefetchQuery({
        queryKey: [QUERY_KEY_CMS_DETAIL_PAGES],
        queryFn: () => getTenantCmsData(tenantId, "detail-pages"),
      });

      queryClient.prefetchQuery({
        queryKey: [QUERY_KEY_TENANT_FOOTER],
        queryFn: () => getTenantFooter(),
      });

      queryClient.prefetchQuery({
        queryKey: [QUERY_KEY_NAV_BAR_ITEMS],
        queryFn: () => getTenantNavbarItems(),
      });

      //
      // Configure Sentry.
      //
      if (config.sentryEnabled) {
        init({
          dsn: config.sentryKey,
          environment: config.environment ?? "production",
          initialScope: {
            tags: {
              version: process.env.REACT_APP_VERSION,
              tenantId: config.cbpTenantId ?? "No tenantId",
            },
          },
          integrations: [
            new BrowserTracing({
              // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
              tracePropagationTargets: [
                "localhost",
                /^https:\/\/yourserver\.io\/api/,
              ],
            }),
          ],
          // Performance Monitoring
          tracesSampleRate: config.sentryTracesSampleRate ?? 0.1,
        });
        setSentryContext();
      }

      //Check is app is being initialized from the mobile app.
      const searchParams = new URLSearchParams(window.location.search);
      const isMobileEmbed = searchParams.get("viewMode") === "mobile";
      const mobileEmbedAccessToken = searchParams.get("mobileToken");
      const cun = searchParams.get("cun");
      const isAuthenticatedView =
        cun && cun !== "null" && mobileEmbedAccessToken !== "null";
      userSession.isMobileViewMode = isMobileEmbed;
      if (isMobileEmbed) {
        //Disable auto zoom on inputs, and scaling.
        const metaTag = document.createElement("meta");
        metaTag.setAttribute("name", "viewport");
        metaTag.setAttribute(
          "content",
          "width=device-width, initial-scale=1, maximum-scale=1",
        );
        document.getElementsByTagName("head")[0].appendChild(metaTag);

        //Hide Userway and react query dev tools (tanstack)
        showUserway = false;
        showReactQueryDevtools = false;
      }

      if (showUserway) {
        // Userway widget: Load it if it's not being loaded from the mobile app. We can also use the tenantConfigGet
        // response to load or hide it at this point.
        (function (d) {
          const s = d.createElement("script");
          /* uncomment the following line to override default position*/
          s.setAttribute("data-position", "3");
          /* uncomment the following line to override default size (values: small, large)*/
          /* s.setAttribute("data-size", "small");*/
          /* uncomment the following line to override default language (e.g., fr, de, es, he, nl, etc.)*/
          /* s.setAttribute("data-language", "language");*/
          /* uncomment the following line to override color set via widget (e.g., #053f67)*/
          /* s.setAttribute("data-color", "#053e67");*/
          /* uncomment the following line to override type set via widget (1=person, 2=chair, 3=eye, 4=text)*/
          /* s.setAttribute("data-type", "1");*/
          /* s.setAttribute("data-statement_text:", "Our Accessibility Statement");*/
          /* s.setAttribute("data-statement_url", "http://www.example.com/accessibility")";*/
          /* uncomment the following line to override support on mobile devices*/
          /* s.setAttribute("data-mobile", true);*/
          /* uncomment the following line to set custom trigger action for accessibility menu*/
          /* s.setAttribute("data-trigger", "triggerId")*/
          s.setAttribute("data-account", data.features.userwayDataAccount);
          s.setAttribute("src", "https://cdn.userway.org/widget.js");
          (d.body || d.head).appendChild(s);
        })(document);
      }

      if (isAuthenticatedView) {
        userSession.accessToken = mobileEmbedAccessToken;
        userSession.cognitoUsername = cun;
      }

      // Moved the render method has been added inside the queryClient fetch query so
      // it waits for Amplify to be initilized and the session check does not fail in the
      // App.tsx session check (that uses Amplify to check the session). Otherwise, if we
      // render the App before initializing Amplify, the session check (using Auth) fails.
      const root = ReactDOM.createRoot(
        document.getElementById("root") as HTMLElement,
      );
      root.render(
        <Provider store={store}>
          {/* <React.StrictMode> */}
          <QueryClientProvider client={queryClient}>
            <App />
            {showReactQueryDevtools && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
          </QueryClientProvider>
          {/* </React.StrictMode> */}
        </Provider>,
      );

      // If you want to start measuring performance in your app, pass a function
      // to log results (for example: reportWebVitals(console.log))
      // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
      reportWebVitals();
      return data;
    });
  });
});

function _setTenantTranslation() {
  const locale = typeof window !== "undefined" && getTenantId();
  i18n.changeLanguage(locale ? locale : "default");
}

function _configureLogRocket(config: ConfigSettings) {
  if (config.logRocketEnabled && !!config.logRocketAppId) {
    LogRocket.init(config.logRocketAppId, {
      release: process.env.REACT_APP_VERSION,
    });
  }
}

function appendThirdPartyTags(tenant: Tenant) {
  const tags = tenant?.features?.thirdPartyTags;

  if (!tags || !tags.length) {
    return;
  }

  tags.forEach((tag) => {
    const script = document.createElement("script");
    script.async = true;
    script.src = tag.uri;

    if (tag.checksum) {
      script.integrity = tag.checksum;
      script.crossOrigin = "anonymous";
    }
    document.head.appendChild(script);
  });
}
