import { getCommunityGroupById } from "@App/api/communityGroups";
import { QUERY_KEY_USER_COMMUNITY_GROUPS } from "@App/constants/queryKeyConstants";
import { UserState } from "@App/store/reducers/userReducer";
import { RootState } from "@App/store/store";
import { GenericDetails, Loader } from "@Components/index";
import { Navbar } from "@Layouts/index";
import { useQuery } from "@tanstack/react-query";
import { sendMobileViewModePostMessage } from "@Utils/utils";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const ClubsAndSocialsDetails = () => {
  const { communityGroupId } = useParams<{ communityGroupId: string }>();
  const storeData = useSelector<RootState, UserState>(
    (state) => state.userProfile,
  );
  const { userInfo } = storeData;

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEY_USER_COMMUNITY_GROUPS, communityGroupId, userInfo?.id],
    queryFn: () => getCommunityGroupById(communityGroupId!),
    enabled: !!communityGroupId,
    select: (data) => data.data,
  });

  useEffect(() => {
    sendMobileViewModePostMessage({ isLoading: false });
  }, []);

  return (
    <Navbar>
      {isLoading && !data ? (
        <Loader
          text={"Loading, please wait"}
          color={"#000"}
          fontColor={"#000"}
          horizontallyCentered
          padding={"100px 20px"}
        />
      ) : (
        <GenericDetails
          details={{
            id: data?.data.id,
            title: data?.data.title,
            description: data?.data.description,
            tags: ["Groups and Clubs"],
            files: data?.data.files,
          }}
        />
      )}
    </Navbar>
  );
};

export default ClubsAndSocialsDetails;
