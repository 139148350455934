import { Tabs } from "@Components/index";
import MyAccount from "./my-account/MyAccount";
import { Navbar } from "@Layouts/index";
import ProfileLogic from "./ProfileLogic";
import "./Profile.scss";

const Profile = () => {
  const { selectedTab, setSelectedTab, accountInfo, isLoading } =
    ProfileLogic();
  return (
    <Navbar>
      <div className="profile">
        <div className="profile-title">Profile & Settings</div>
        <div className={"profile-tabs"}>
          <Tabs
            tabs={[
              { label: "Account", isActive: true },
              // { label: "Privacy Policy" }, CBP-2838 hide until further notice
              /* { label: "Terms & Conditions" }, CBP-2714 hide until further notice */
            ]}
            onTabChange={setSelectedTab}
          />
        </div>

        <div className="profile-tab-content">
          {selectedTab === 0 && (
            <MyAccount accountInfo={accountInfo} isLoading={isLoading} />
          )}
        </div>
      </div>
    </Navbar>
  );
};

export default Profile;
