import { sendMobileViewModePostMessage } from "@Utils/utils";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeElementsProps } from "./StripeElementsPropTypes";
import { useState } from "react";

const StripeElementsLogic = (props: StripeElementsProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<null | string>(null);
  const [isPaymentEnabled, setIsPaymentEnabled] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  const handleChange = (event: any) => {
    setIsPaymentEnabled(!event?.complete || event?.empty);
  };

  const handleClick = async () => {
    if (!stripe || !elements) {
      return;
    }
    setIsPaymentEnabled(false);
    setIsPaymentLoading(true);
    const { error: submitError } = await elements.submit();

    if (submitError?.message) {
      setError(
        "There are errors in your payment information. Please correct and try again.",
      );
      setIsPaymentEnabled(true);
      setIsPaymentLoading(false);
      sendMobileViewModePostMessage({ isLoading: false });
      return;
    } else {
      setError(null);
    }
    sendMobileViewModePostMessage({
      isLoading: true,
      text: "Processing Payment, please wait",
    });
    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret: props.clientSecret,
      redirect: "if_required",
      confirmParams: {
        return_url: props.redirectUrl,
      },
    });

    /* 
      Success or Error, the payment loading needs to be set to false, 
      and we need to tell the native mobile app that the payment is 
      not loading anymore 
    */
    sendMobileViewModePostMessage({ isLoading: false });

    if (error) {
      // Todo check error types and add Error Element
      setError(
        "There are errors in your payment information. Please correct and try again.",
      );
      setIsPaymentLoading(false);
      setIsPaymentEnabled(true);
    } else {
      setError(null);
      props.nextStep();
    }
  };

  return {
    isPaymentLoading,
    handleClick,
    handleChange,
    error,
    isPaymentEnabled,
  };
};

export default StripeElementsLogic;
