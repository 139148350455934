import React from "react";
import "./Dropdown.scss";
import { DropdownItem, DropdownProps } from "./DropdownPropTypes";
import DropdownLogic from "./DropdownLogic";
import { ToolTip } from "@Components/index";

const DropdownItems = ({
  items,
  setSelected,
  setIsExpanded,
  onChange,
  dataCy,
}: any) => {
  const handleSelected = (componentProps: DropdownItem) => {
    setSelected(componentProps.label);
    setIsExpanded(false);
    onChange && onChange(componentProps);
  };

  return (
    <ul>
      {items?.map((item: DropdownItem, index: number) => (
        <li key={`dropdown_item${index}`}>
          <button
            onClick={() => {
              handleSelected(item);
            }}
            data-cy={dataCy + "-item-" + index.toString()}>
            {item.label}
          </button>
        </li>
      ))}
    </ul>
  );
};

const Dropdown = (componentProps: DropdownProps) => {
  const props = DropdownLogic(componentProps);

  return (
    <React.Fragment>
      <div className={"dropdown"} aria-haspopup="true">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          {!!componentProps.label && (
            <label className={"dropdown-label"}>{`${componentProps.label}${
              componentProps.required ? "*" : ""
            }`}</label>
          )}
          {!!componentProps.tooltip && (
            <ToolTip
              tooltipText={componentProps.tooltip}
              tooltipPosition={componentProps.tooltipPosition}
            />
          )}
        </div>

        <button
          className={`btn-dropdown ${props.isExpanded && "is-open"}`}
          disabled={componentProps.disabled}
          onClick={() => {
            props.setIsExpanded(!props.isExpanded);
          }}
          data-cy={componentProps.dataCy}>
          <span style={{ marginTop: 3 }}>
            {props.selected?.length ? props.selected : "Select"}
          </span>
        </button>
        <nav
          className={`nav-dropdown ${props.isExpanded && "is-open"}`}
          style={{ maxHeight: componentProps.maxHeight ?? "200px" }}>
          <DropdownItems
            items={componentProps.items}
            setSelected={props.setSelected}
            setIsExpanded={props.setIsExpanded}
            onChange={componentProps.onChange}
            dataCy={componentProps.dataCy}
          />
        </nav>
      </div>
    </React.Fragment>
  );
};

export default Dropdown;
