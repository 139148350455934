import { useState } from "react";
import { DropdownProps } from "./DropdownPropTypes";

const DropdownLogic = (props: DropdownProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selected, setSelected] = useState(
    props.initialValue
      ? props.items.find((item) => item.value === props.initialValue)?.label
      : "",
  );
  return { isExpanded, selected, setIsExpanded, setSelected };
};

export default DropdownLogic;
