import { InitiatePaymentRequest } from "@Api/requests/payments";
import { Event, EventPagedResponse } from "@App/models/event";
import { EventCategory } from "@App/models/eventCategory";
import { format, parseISO } from "date-fns";
import { baseServiceRequest } from "./baseService";
import {
  BookRecurringEventRequest,
  EventCalendarDatesParams,
  EventsSearchParams,
  RecurringEventDatesRequest,
  ReserveFreeTicketRequest,
  UpdateEventUserPreferencesProps,
} from "./requests/events";

export const searchPagedEvents = async (params: EventsSearchParams) => {
  return await baseServiceRequest<EventPagedResponse>({
    path: `/event/Search`,
    type: "get",
    useAuthHeader: true,
    params,
  });
};

// TODO: Fix this bad route naming
export const getEventCalendarDates = async (
  params: EventCalendarDatesParams,
) => {
  return await baseServiceRequest<string[]>({
    path: `/event/search/calendar`,
    type: "get",
    useAuthHeader: true,
    params,
  });
};

export const getEventDetails = async (eventId: string) => {
  return await baseServiceRequest<Event>({
    path: `/event/${eventId}`,
    type: "get",
    useAuthHeader: true,
  });
};

export const getEventCategories = async () => {
  return await baseServiceRequest<EventCategory[]>({
    path: `/eventcategories`,
    type: "get",
  });
};

export const updateEventPreference = async (
  props: UpdateEventUserPreferencesProps,
) => {
  return await baseServiceRequest<object>({
    path: `/event/${props.eventId}/user/${props.userId}`,
    type: "put",
    postData: props.preference,
    useAuthHeader: true,
  });
};

export const getEventAvailableTickets = async (eventId: string) => {
  return await baseServiceRequest({
    path: `/event/${eventId}/ticketsforPurchase`,
    type: "get",
    useAuthHeader: true,
  });
};

export const initiateEventTicketPurchase = async (
  data: InitiatePaymentRequest,
) => {
  return await baseServiceRequest({
    path: `/stripe/InitiatePaymentRequest`,
    type: "post",
    useAuthHeader: true,
    postData: data,
  });
};

/**
  @deprecated
  Only used for ticket purchase modal which has been turned off.
  Remove once puchase modal is refactored
*/
export const getRecurringEventDates = async (
  req: RecurringEventDatesRequest,
) => {
  const { eventId, startDate, endDate } = req;
  return await baseServiceRequest({
    path: `/event/${eventId}/recurringDates`,
    type: "get",
    useAuthHeader: true,
    params: { startDate, endDate },
  });
};

export const createRecurringEventForGivenDate = async (
  req: BookRecurringEventRequest,
) => {
  const { baseEventId, startDate, endDate, selectedDate } = req;
  return await baseServiceRequest({
    path: `/event/${baseEventId}/book/${format(parseISO(selectedDate), "yyyy-MM-dd")}`,
    type: "post",
    useAuthHeader: true,
    postData: { startDate, endDate },
  });
};

//Cart/Event/ReserveFreeTickets

export const reserveFreeTickets = async (req: ReserveFreeTicketRequest) => {
  return await baseServiceRequest({
    path: `/cart/event/ReserveFreeTickets`,
    type: "post",
    useAuthHeader: true,
    postData: req,
  });
};
