import { useEffect, useState } from "react";
import { Tab, TabsProps } from "./TabsPropTypes";

const TabsLogic = (props: TabsProps) => {
  const [isAllSelected, setIsAllSelected] = useState(props.showAll);
  const [tabs, setTabs] = useState<Tab[]>([]);

  useEffect(() => {
    if (tabs.length === 0 || props.dynamicTabs) {
      const tabElements = props.tabs.map((tab, index) => {
        return {
          label: tab.label,
          index: index,
          value: tab.value,
          isActive: props.showAll ? false : tab.isActive,
          icon: tab.icon,
          isHidden: tab.isHidden,
          iconUrl: tab.iconUrl,
        };
      });
      setTabs(tabElements);
    }
  }, [props.tabs, props.showAll, props.dynamicTabs, tabs.length]);

  const handleSelectedTab = (tabIndex: number) => {
    if (tabIndex !== -1) {
      setIsAllSelected(false);
    }
    let updatedTabs: Tab[] = [];
    tabs?.forEach((tab) => {
      tab.isActive = tab.index === tabIndex;
      updatedTabs.push(tab);
    });
    setTabs(updatedTabs);
    props.onTabChange && props.onTabChange(tabIndex);
  };

  return { tabs, isAllSelected, handleSelectedTab, setIsAllSelected };
};

export default TabsLogic;
