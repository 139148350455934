import { useSelector } from "react-redux";
import { RootState } from "@App/store/store";

import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_USER_DETAILS } from "@App/constants/queryKeyConstants";
import { getUserAccountInfo } from "@App/api/user";
import { UserResponse, AccountInfo } from "./ProfilePropTypes";
import { useState } from "react";

const ProfileLogic = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const userProfile: UserResponse = useSelector(
    (state: RootState) => state.userProfile,
  );
  const userId = userProfile?.userInfo?.id;

  const { data: accountInfo, isLoading } = useQuery(
    [QUERY_KEY_USER_DETAILS, userId],
    () => {
      return getUserAccountInfo(userId).then((res) => res.data as AccountInfo);
    },
    {
      enabled: !!userId,
    },
  );
  return { selectedTab, setSelectedTab, accountInfo, isLoading };
};

export default ProfileLogic;
